import React, { PureComponent } from 'react';
import classNames from 'classnames';
import enhanceWithClickOutside from 'react-click-outside';

import RoundButton from '../round-button/round-button';
import FormErrorMessage from '../form-error-message/form-error-message';

import './text-input.scss';

class TextInput extends PureComponent {
  constructor() {
    super();
    this.inputRef = React.createRef();
    this.state = {
      editing: false
    };
  };
  updateValue = e => {
    if (this.props.isDisabled) return;
    if (!e.isTrusted) return; // disable autofill
    if (this.props.preventDefaultOnEnter) {
      e.preventDefault();
    }
    this.props.updateValue({
      value: e.target.value,
      forLabel: this.props.forLabel
    });
  };
  toggleEditing = () => {
    this.setState({
      editing: !this.state.editing
    }, () => this.inputRef.current.focus());
  };
  removeSelectedOption = () => {
    this.props.removeSelectedOption();
    this.inputRef.current.focus();
  }

  handleClickOutside = e => {
    this.setState({
      editing: false
    }, () => {
      if (this.props.onBlur) {
        this.props.onBlur(this.props.value);
      }
    });
  };

  render() {
    const { 
      label,
      forLabel,
      type,
      value,
      hideInitEdit,
      modifierClass,
      additionalInputProps,
      hasSpinner,
      loading,
      selectedOption,
      isValid,
      validationErrorMsg,
      emailUnique,
      isEditable,
      renderStaticAsLink,
      maxLength
    } = this.props;

    const { editing } = this.state;

    return (
      <div 
        className={
          classNames('text-input', { 
            [`text-input--${modifierClass}`]: modifierClass
          })} 
        ref="nameInput"
      >
        <FormErrorMessage
          isValid={isValid}
          validationErrorMsg={validationErrorMsg}
        />
        {forLabel === 'email' && (
          <FormErrorMessage
            isValid={emailUnique !== false}
            validationErrorMsg="There is already an account associated with this email."
          />
        )}
        {!hideInitEdit || editing ? (
          <label htmlFor={forLabel}>
            <input
              className={classNames('text-input__input', {
                [`text-input__input--${modifierClass}`]: modifierClass,
                ['text-input__input--has-spinner']:hasSpinner
              })}
              onChange={this.updateValue}
              ref={this.inputRef}
              value={value}
              id={forLabel}
              autoComplete="off"
              maxLength={maxLength ? maxLength : 120}
              type={type}
              placeholder={label}
              {...additionalInputProps}
            />
            {selectedOption && (
              <div className="text-input__selected-option">
                <div className="text-input__selected-option__inner">
                  <div className="text-input__selected-option__text">{selectedOption}</div>
                  <RoundButton onClick={this.removeSelectedOption} />
                </div>
              </div>
            )}
            {hasSpinner && loading && (
              <div className="text-input__spinner">
                <img src="/static/icons/spinner.svg" alt="logo" />
              </div>              
            )}
          </label>
        ) : (
          <div className="text-input__static">
            <div className="text-input__static__value">
              {renderStaticAsLink ? (
                <a rel="noopener noreferrer" target="_blank" href={value}>{value}</a>
              ) : value}
              {isEditable && (
              <span className="text-input__static__edit">
                <button onClick={this.toggleEditing}>Edit</button>
              </span>
            )}              
            </div>
          </div>
        )}
      </div>
    );
  }
};

TextInput.defaultProps = {
  hideInitEdit: false,
  modifierClass: null,
  hasSpinner: false,
  loading: false,
  selectedOption: undefined,
  removeSelectedOption: null,
  isEditable: false
};

export default enhanceWithClickOutside(TextInput);
