import React, { PureComponent } from 'react';
import './round-button.scss';

class RoundButton extends PureComponent {
  render() {
    const { type, onClick } = this.props;
    return (
      <div className={`round-button round-button--${type}`}>
        <button onClick={onClick} />
      </div>
    );
  }
}

RoundButton.defaultProps = {
  type: 'close'
};

export default RoundButton;
