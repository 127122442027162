import React, { PureComponent } from 'react';
import { debounce } from "debounce";
import classNames from 'classnames';
import './form-error-message.scss';

class FormErrorMessage extends PureComponent {
  constructor() {
    super();
    this.state = {
      height: 0,
      opacity: 0
    };

    this.containerRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", debounce(this.setHeight, 50));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", debounce(this.setHeight, 50));
  }  

  setHeight = () => {
    if (this.props.isValid) return;
    if (!this.containerRef.current) return;

    const nodeHeight = this.containerRef.current.getBoundingClientRect().height;
    this.setState({
      height: `${nodeHeight + 20}px`,
      opacity: 1
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.isValid) {
      this.setHeight();
    } else {
      this.setState({
        height: 0,
        opacity: 0
      });
    }
  }

  render() {
    const { validationErrorMsg, isValid, noChevron } = this.props;
    const { height, opacity } = this.state;

    return (
      <div
        className={classNames("form-error-msg", {
          'form-error-msg--no-chevron': noChevron
        })}
        style={{ height, opacity }}
      >
        {!isValid && (
          <div ref={this.containerRef} className="form-error-msg__inner">
            {validationErrorMsg}
          </div>
        )}
      </div>
    );
  }
}

FormErrorMessage.propTypes = {

};

export default FormErrorMessage;